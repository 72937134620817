<template>
    <dashboardLayout>
        <navigation-container :btn="{title: 'Назад', route: 'sportsmans'}">

            <div class="flex justify-center mb-10">
                <div class="w-full lg:w-auto">
                    <div class="prof_container relative flex">
                        <wrap-section :is-empty=true>
                            <h2 class="text-2xl md:text-4xl ">Редактирование профиля спортсмена</h2>
                        </wrap-section>
                    </div>
                    <div class="prof_container relative flex" id="photo">
                        <wrap-section>
                            <span class="text-dark-base text-xl font-bold">Фото</span><br><br>
                            <div class="flex flex-wrap sm:flex-nowrap">
                                <div v-if="fd.avatar"
                                     class="sm:mr-5 flex items-center justify-center w-28 h-28 sm:w-32 mx-auto">
                                    <img :src="fd.avatar" alt="" class="rounded-full">
                                </div>
                                <div class="w-full mt-4 sm:mt-0">
                                    <span class="font-bold">Загрузите фотографию</span> <br>
                                    <span class="text-base">В форматах JPG, PNG</span>
                                    <div class="mt-3">
                                        <file-upload uploadType="sportsmanAvatar" message="Ошибка при загрузке Аватара"
                                                     :return-url="true"/>
                                    </div>
                                </div>
                            </div>
                        </wrap-section>
                    </div>
                    <div class="prof_container mt-8" id="personal">
                        <wrap-section>
                            <div class="flex justify-between">
                                <span class="text-dark-base text-xl font-bold">Персональные данные</span>
                                <div class="d_center">
                                    <validate-modal-sportsman type="sportsman" :modStatus="fd.status"/>
                                </div>
                            </div>
                            <br><br>
                            <personal-data type="sportsman" :data="fd"/>
                        </wrap-section>
                    </div>

                    <div class="prof_container mt-8">
                        <profile-transfer />
                    </div>
                </div>
            </div>
        </navigation-container>
    </dashboardLayout>
</template>

<script setup>

import dashboardLayout from "@/views/layout/dashboard";
import WrapSection from "@/views/components/helpers/wrapSection.vue";
import FileUpload from "@/views/components/dashboard/fileUpload.vue";
import store from "@/store";
import useEmitter from "@/utils/useEmitter";
import NavigationContainer from "@/views/layout/components/navigation-container.vue";
import PersonalData from "@/views/components/dashboard/personalData.vue";
import {onMounted, ref} from "vue";
import {useRoute} from "vue-router";
import ValidateModalSportsman from "@/views/components/modal/validateModalSportsman.vue";
import {apiRequest} from "@/utils/helpers";
import {USER_DATA} from "@/router/api";
import InputCustom from "@/views/components/inputComponent/inputCustom.vue";
import ProfileTransfer from "@/views/pages/dashboard/sportsmans/profileTransfer.vue";

const route = useRoute();
const emitter = useEmitter();
const fd = ref({});


onMounted(async () => {
    await apiRequest(USER_DATA + '?uuid=' + route.params.id, [], 'GET')
        .then(resp => {
            if (resp && resp.success) {
                Object.assign(fd.value, resp.result);
                fd.value.updateSportsman = route.params.id;
                fd.value.address = {
                    "city": resp.result.location.city,
                    "country": resp.result.location.country,
                    "region": resp.result.location.region
                };
            }
        })
        .catch(e => {
            console.log('error', e)
        })
        .finally(() => {
        });
});

emitter.on('returnUrl', value => {
    if (value && value.url && value.uploadType === 'sportsmanAvatar') {
        fd.value.avatar = value.url;
        fd.value.avatar_update = true;
    }
});


</script>

