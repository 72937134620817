<template>
  <div
    class="sidebar-padding flex cursor-pointer mt-5 text-white text-center w-full"
    @click="handleLogout"
    :class="addClass"
  >
    Выйти из аккаунта
    <div class="flex items-center ml-3 sidebar_icon" :class="{ 'absolute right-10': addClass }">
      <icon icon-name="logout" />
    </div>
  </div>
</template>

<script setup>
import { logout } from "@/utils/helpers";
import Icon from "@/views/components/helpers/icon.vue";

defineProps({
  addClass: {
    type: String,
    default: null,
  },
});
const handleLogout = () => {
  logout();
};
</script>
