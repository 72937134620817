<template>

  <div class="w-full">
    <div v-for="(el, index) in discounts" :key="index">
      <div class="w-full h-0.5 bg-light-border mt-8 mb-5" v-if="index > 0"/>
      <div class="flex justify-between mt-5 mb-5">
        <div class="font-bold text-xl">{{ el.discount_type?.title }}</div>
        <div>
          <delete-registration :uuid="index" type="discount"/>
        </div>

      </div>

      <div v-if="el.discount_type?.slug === 'early_reg'">
        <early-reg-offer-item :index="index" offer-type="early_reg" :data="el" :is-disabled="isOfferDisabled(index)"/>
      </div>
      <div v-if="el.discount_type?.slug === 'qnt_reg'">
        <qnt-registration :index="index" offer-type="qnt_reg" :data="el" :is-disabled="isOfferDisabled(index)"/>
      </div>
      <div v-if="el.discount_type?.slug === 'few_reg_sportsman'">
        <few-registration :index="index" offer-type="few_reg_sportsman" :data="el" :is-disabled="isOfferDisabled(index)"/>
      </div>
    </div>

    <div class="w-full h-0.5 bg-light-border mt-10 mb-5" v-if="store.state.discount.length > 0"/>

    <alerts alertType="competitionOffer"/>

    <div class="wrap_input mt-5" v-bind="$attrs">
      <div class="label-personal-page font-bold text-sm flex justify-normal items-center">Укажите акцию</div>
      <VueMultiselect
        v-model="fd.type"
        :options="offerType"
        track-by="id"
        label="title"
        deselect-label="Удалить"
        select-label="Выбрать"
        selected-label="Выбрано"
        :multiple="false"
        placeholder="Не выбрана"
      >
        <template v-slot:noResult class="no-result">
          <span>Тип акции не выбран</span>
        </template>
      </VueMultiselect>

    </div>
    <div>
      <button class="btn mt-8 btn_blue w-full sm:w-64 d_center" @click="saveCompetitionDiscount"
              :disabled="isLoading">
        <icon icon-name="spinner_white" class="mr-3" v-if="isLoading"/>
        Сохранить
      </button>
    </div>
  </div>


</template>

<script setup>
import {computed, onMounted, ref, watch} from "vue";
import useEmitter from "@/utils/useEmitter";
import {useRoute} from "vue-router";
import VueMultiselect from "vue-multiselect";
import QntRegistration from "@/views/components/createCompetition/discount/qntRegistration.vue";
import FewRegistration from "@/views/components/createCompetition/discount/FewRegistration.vue";
import {apiRequest} from "@/utils/helpers";
import {EVENT_OFFERS, EVENTS} from "@/router/api";
import EarlyRegOfferItem from "@/views/components/createCompetition/discount/earlyRegOfferItem.vue";
import Icon from "@/views/components/helpers/icon.vue";
import DeleteRegistration from "@/views/components/dashboard/deleteRegistration.vue";
import Alerts from "@/views/components/helpers/alerts.vue";
import {useStore} from "vuex";

const props = defineProps({
  data: {
    type: Object,
    default: {},
  },
})
const store = useStore();
const discounts = computed(() => store.state.discount);
const offerType = ref([]);
const route = useRoute();
const emitter = useEmitter();
const isLoading = ref(false);
const fd = ref({
  type: {},
  conditions_info: '',
  offer: '',
})

function isOfferDisabled(index) {
  return !(store.state.discount.length - 1 === index);
}

const getEventOffers = () => {
  apiRequest(EVENT_OFFERS + '?event_uuid=' + route.params.id, [], 'get')
    .catch(e => {
      console.error("Ошибка", e);
    })
    .then(async resp => {
      if (resp && resp.success && resp.result && resp.result.discount_types) {
        offerType.value = resp.result.discount_types;
        store.commit('clearDiscount');
        if (resp.result.discounts && resp.result.discounts.length) {
          resp.result.discounts.forEach(el => {
            store.commit('updateDiscount', el);
          })
        }
        // if (resp.result.price && resp.result.price.length) {
        store.commit('updateDiscountPriceType', getPriceValue(resp.result.price));
        // }
      }
    })
}

onMounted(() => {
  getEventOffers();
});

const getPriceValue = newValue => {
  if (newValue) {
    return [
      {
        id: 'all',
        price: 'all',
        title: 'Все категории'
      },
      ...newValue
        .filter(item => item.direction_category?.title)
        .map(item => ({
          id: item.id,
          price: item.price,
          title: `${new Intl.NumberFormat('ru-RU')
            .format(parseFloat(item.price))} ₽ ${item.direction_category.title}`
        }))
    ];
  }
};

let deletingInProgress = new Set();

emitter.on('delete-registration', value => {
  if (!value) return;
  if (value.type === "discount") {
    const uuid = value.uuid;
    if (!deletingInProgress.has(uuid)) {
      deletingInProgress.add(uuid);
      store.commit('removeDiscountByIndex', uuid);
      setTimeout(() => {
        deletingInProgress.delete(uuid);
      }, 1000);
    }
  }
});

const validation = () => {
  let element = store.state.discount.at(-1);
  let base = 'Проверьте корректность ввода поля '


  if (element) {
    if (element.discount_type.slug === 'few_reg_sportsman' || element.discount_type.slug === 'qnt_reg') {
      console.log('validation', element)
      if (element.registration_quantity < 1) return emitter.emit("alert", {
        alertType: 'competitionOffer',
        message: base + ' Колличество участников'
      });
      if (element.discount_percent < 1 || element.discount_percent > 100) return emitter.emit("alert", {
        alertType: 'competitionOffer',
        message: base + ' Процент скидки'
      });
    }

    if (element.discount_type.slug === 'early_reg') {
      if (!element.date_start) return emitter.emit("alert", {
        alertType: 'competitionOffer',
        message: base + ' Дата начала'
      });
      if (!element.date_end) return emitter.emit("alert", {
        alertType: 'competitionOffer',
        message: base + ' Дата окончания'
      });
      if (!element.is_all_category && !element.price_id) return emitter.emit("alert", {alertType: 'competitionOffer', message: base + ' Выберите цену'});
      if (!element.discount_percent) return emitter.emit("alert", {
        alertType: 'competitionOffer',
        message: base + ' Процент  скидки'
      });
    }

  }

  return true;
}

watch(
  fd,
  () => {
    if (fd.value.type && fd.value.type.id) {
      if(!validation()) {
        return ;
      }
      store.commit('updateDiscount',
        {
          "id": null,
          "event_id": null,
          "discount_type": fd.value.type,
          "price_id": null,
          "date_start": null,
          "date_end": null,
          "discount_percent": null,
          "discount_value": null,
          "registration_quantity": null,
          "is_all_category": fd.value.type.slug === 'early_reg'
        }
      )
    }
  },
  {deep: true}
);

const saveCompetitionDiscount = () => {
    if (validation()) {
        isLoading.value = true;

        apiRequest(EVENTS + '/' + route.params.id, {type: 'discounts', data: store.state.discount}, 'put')
            .then(resp => {
                if (resp && resp.success && resp.message) {
                    emitter.emit("success", {alertType: 'competitionOffer', message: resp.message});
                }
            })
            .catch(e => {
                if(e.message) {
                    emitter.emit("alert", {alertType: 'competitionOffer', message: e.message});
                } else {
                    emitter.emit("alert", {alertType: 'competitionOffer', message: 'Ошибка сервера! Обратитесь в поддержку или повторите позже.'});
                }
                console.log('error', e)
            })
            .finally(() => {
                isLoading.value = false;
            });

    }
}

</script>