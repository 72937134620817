<template>
    <dashboardLayout>
        <navigation-container class="mt-10">


            <wrap-section :is-empty="true">
                <div class="sm:flex">
                    <div class="flex-1 flex items-center">
                        <h2 class="md:text-4xl text-2xl">Мои спортсмены</h2>
                    </div>
                </div>

                <div v-if="store.state?.permissions?.includes('public_create_sportsman')" class="flex justify-left mt-5 sm:hidden">
                    <add-new-item-button class="mt-4 sm:text-right sm:mt-0"
                                         @click="createSportsman">
                        <icon icon-name="white_plus" class="inline-block -mb-0.5"/>
                        Создать спортсмена
                    </add-new-item-button>
                </div>
            
                <Tabs>
                    <Tab active="true" title="Мои спортсмены">
                        <mySportsmansTab ref="trainersSportsmans"/>
                    </Tab>
                    <Tab title="Поиск спортсменов">
                        <wrap-section>
                            <searchSportsmansTab ref="sportsmans"/>
                        </wrap-section>
                    </Tab>
                </Tabs>
            </wrap-section>

            <div v-if="store.state?.permissions?.includes('public_create_sportsman')">
                <wrap-section :is-empty="true">
                    <div class="flex justify-left">
                        <add-new-item-button class="mt-4 sm:text-right sm:mt-0"
                                             @click="createSportsman">
                            <icon icon-name="white_plus" class="inline-block -mb-0.5"/>
                            Создать спортсмена
                        </add-new-item-button>
                    </div>
                </wrap-section>

                <wrap-section :is-empty="true">
                    <div>
                        Вы можете отправить ссылку на создание спортсмена с привязкой к вашему аккаунту
                    </div>
                    <div class="flex mt-3">
                        <div class="h-10 w-full bg-dark-border rounded-md mr-3 flex items-center pl-3 pr-3 sm:pl-10 whitespace-nowrap overflow-hidden">
                            {{ getSportsmanRegLink() }}
                        </div>
                        <div class="cursor-pointer btn_blue w-10 h-10 rounded-md flex-col justify-center items-center inline-flex"
                             @click="copyLink">
                            <icon :icon-name="iconName" class="inline-block -mb-0.5"/>
                        </div>
                    </div>
                </wrap-section>
            </div>


        </navigation-container>
    </dashboardLayout>
</template>

<script setup>
import {navigateTo, uuid} from "@/utils/helpers";
import {ref} from "vue";
import dashboardLayout from "@/views/layout/dashboard";
import wrapSection from "@/views/components/helpers/wrapSection";
import addNewItemButton from "@/views/components/helpers/addNewItemButton";
import sportsman from "./sportsman.vue";
import mySportsman from "./mySportsman.vue";
import store from "@/store";
import icon from "@/views/components/helpers/icon.vue";
import NavigationContainer from "@/views/layout/components/navigation-container.vue";
import Tabs from "@/views/components/helpers/Tabs.vue";
import Tab from "@/views/components/helpers/Tab.vue";
import searchSportsmansTab from "./searchSportsmansTab.vue";
import mySportsmansTab from "./mySportsmansTab.vue";
import useEmitter from "@/utils/useEmitter";
import router from "@/router/router";

let input = ref("");

const trainersSportsmans = ref();
const sportsmans = ref();
const iconName   = ref('copy_white');

const emitter = useEmitter();

const createSportsman = () => {
    router.push({name: 'addNewSportsman', params: {id: uuid()}})
}
const getSportsmanRegLink = () => {
  const currentHost = window.location.origin;
  return currentHost + '/registration/sportsman?t=' + store.state.user.id;
}

const copyLink = () => {
    const link = getSportsmanRegLink();
    navigator.clipboard.writeText(link)
        .then(() => {
            iconName.value = 'checkmark_white';
            setTimeout(() => {
                iconName.value = 'copy_white';
            }, 2000);
        })
        .catch(err => {
            console.error("Ошибка копирования: ", err);
        });
}

emitter.on('tabIndex', (index) => {
    index === 0 ?  trainersSportsmans.value.loadTrainersSportsmans() :  sportsmans.value.loadSportsmans();
});

</script>
