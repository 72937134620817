<template>
    <wrap-section>
        <div class="text-dark-base text-xl font-bold">Передать управление профилем</div>
        <div class="text-base mt-5">Вы можете передать управление аккаунтом спортсмену или его представителю,
            для этого введите e-mail представителя, он получит на почту логин и пароль для входа в
            профиль, при этом вы потеряете доступ к редактированию и управлению аккаунтом.
        </div>

        <input-custom label-class="min-w-24 font-bold" label="Email*" type="text" v-model="email" class="mt-5"/>

        <alerts alertType="profileTransfer"/>

        <button class="btn mt-8 btn_blue w-full sm:w-64 d_center" @click="submitButton"
                :disabled="isLoading">
            <icon icon-name="spinner_white" class="mr-3" v-if="isLoading"/>
            Передать профиль
        </button>
    </wrap-section>
</template>

<script setup>
import WrapSection from "@/views/components/helpers/wrapSection.vue";
import useEmitter from "@/utils/useEmitter";
import {ref} from "vue";
import {useRoute} from "vue-router";

import InputCustom from "@/views/components/inputComponent/inputCustom.vue";
import Alerts from "@/views/components/helpers/alerts.vue";
import {apiRequest} from "@/utils/helpers";
import {EVENTS, PROFILE_TO_REPRESENT} from "@/router/api";
import Icon from "@/views/components/helpers/icon.vue";
import router from "@/router/router";

const route = useRoute();
const emitter = useEmitter();
const email = ref('');
const isLoading = ref(false);

const submitButton = () => {
    email.value = email.value.trim();
    if (!email.value || email.value.length < 5 || !email.value.includes('@')) {
        return emitter.emit("alert", {
            alertType: 'profileTransfer',
            message: "Для передачи управления профилем, укажите рабочий Email"
        });
    }
    isLoading.value = true;

    apiRequest(PROFILE_TO_REPRESENT, {
        represent_email:    email.value,
        sportsman_uuid:     route.params.id,
    }, 'post')
        .catch(e => {
            if (e.message) {
                return emitter.emit("alert", {alertType: 'profileTransfer', message: e.message});
            }
            return emitter.emit("alert", {
                alertType: 'profileTransfer',
                message: 'Ошибка сервера! Обратитесь в поддержку или повторите позже.'
            });

        })
        .then(resp => {
            if (resp && resp.success && resp.message) {
                emitter.emit("success", {alertType: 'profileTransfer', message: resp.message});
            } else {
                return emitter.emit("alert", {
                    alertType: 'profileTransfer',
                    message: "Ошибка валидации"
                });
            }
        })
        .finally(() => {
            isLoading.value = false;
        });

}


</script>

