<template>

    <input-search v-model="search" @input="searchHandler" label="Поиск тренера"/>

    <div class="divide-y divide-light-border divide-solid mt-7">
        <trainer-item v-if="data?.length" v-for="el in data" :sportsmanItem="el" :key="el.uuid"/>
        <div v-else>
            Тренеров по заданным критериям не найдено
        </div>
    </div>

    <pagination :meta="meta" v-model="currentPage"/>

</template>

<script setup>
import {apiRequest, debounce} from "@/utils/helpers";
import InputSearch from "@/views/components/inputComponent/inputSearch.vue";
import {onMounted, ref, watch} from "vue";
import Pagination from "@/views/components/helpers/pagination.vue";
import { ALL_TRAINERS_SEARCH } from "@/router/api";
import TrainerItem from "@/views/pages/dashboard/trainers/trainerItem.vue";

const meta = ref({});
const input = ref("");
const data = ref([]);
const currentPage = ref(1);
const search = ref('');

const searchHandler = debounce(() => {
    currentPage.value = 1;
    loadTrainers();
}, 400);

const loadTrainers = async (page = 1, onMounted = false) => {
    try {

        if(search.value.trim().length < 3 && search.value.trim().length > 0 && !onMounted) {
            return false;
        }

        const response = await apiRequest(
            ALL_TRAINERS_SEARCH + (onMounted ? '' : '?page=' + page + '&search=' + search.value.trim()),
            [],
            'get'
        );

        if (response && response.success) {
            data.value = response.result;
            meta.value = response.result.pagination ?? {};
            console.log(data.value)
        }
    } catch (e) {
        console.error('Ошибка при загрузке данных о спортсменах:', e);
    }
};


watch(currentPage, (newPage) => {
    loadTrainers(newPage);
});

onMounted(async() => {
    await loadTrainers(currentPage.value, true);
});


</script>
