<template>
    <div class="flex sportsman_item pb-7 pt-7" :class="{ 'bg-pink-delete': !undelited, '': undelited }">
        <base-user-info :data="sportsmanItem"/>
        <div class="flex-auto text-right relative">
            <div v-if="sportsmanItem.can_edit && sportsmanItem.is_trainer_represent">
                <button @click="editSportsman">
                    <span class="text-base-color hidden md:inline">Редактировать профиль</span>
                    <icon icon-name="edit" class="inline-block md:hidden"/>
                </button>
            </div>
            <div class="absolute bottom-0 right-0">
                <button v-if="undelited" @click="deleteSportsmanFromFriends" :disabled="isLoading"
                        :class="{ 'hidden': isLoading, 'md:inline': !isLoading }">
                    <span class="text-base-color hidden md:inline">Удалить из списка</span>
                    <icon icon-name="delete" class="inline-block md:hidden"/>
                </button>
                <button v-else @click="unDeleteSportsmanFromFriends" :disabled="isLoading"
                        :class="{ 'hidden': isLoading, 'md:inline': !isLoading }">
                    <span class="text-base-color hidden md:inline">Отменить удаление</span>
                    <icon icon-name="delete" class="inline-block md:hidden"/>
                </button>
                <div v-if="isLoading" class="loader inline-block ml-1"></div>
            </div>
        </div>
    </div>
</template>

<script>
import avatarMale from "@/../public/img/avatar/male.svg";
import avatarFemale from "@/../public/img/avatar/female.svg";
import icon from "@/views/components/helpers/icon.vue";
import {apiRequest, navigateTo} from "@/utils/helpers";
import {SPORTSMAN, SPORTSMANS} from "@/router/api";
import BaseUserInfo from "@/views/components/profile/baseUserInfo.vue";
import router from "@/router/router";

export default {
    data: () => {
        return {
            avatarMale: avatarMale,
            avatarFemale: avatarFemale,
            isLoading: false, // отслеживание состояния загрузки
            undelited: true,
        }
    },
    props: {
        sportsmanItem: {
            type: Object,
            required: true,
        }
    },
    components: {
        BaseUserInfo,
        icon
    },
    computed: {
        sex() {
            return this.sportsmanItem.sex === 'male' ? 'Муж' : 'Жен';
        },
        avatar() {
            return this.sportsmanItem.sex === 'male' ? avatarMale : avatarFemale;
        },
        isAuthorised() {
            return this.sportsmanItem.isValidate === 1 ? 'authorised' : 'notAuthorised';
        }
    },
    methods: {
        editSportsman() {
            router.push({
                name: 'editSportsman',
                params: {id: this.sportsmanItem.uuid},
                // query: { data: JSON.stringify(this.sportsmanItem) }
            })
        },

        deleteSportsmanFromFriends() {
            if (this.isLoading) return;
            const uuid = this.sportsmanItem.uuid;
            const requestData = {uuid};
            this.isLoading = true;
            apiRequest(SPORTSMAN, requestData, 'delete')
                .then(resp => {
                    if (resp && resp.success) {
                        this.isFriendAdded = true;
                        this.$emit('sportsmanDeleted', uuid);
                    }
                })
                .catch(e => {
                    console.log('error', e)
                })
                .finally(() => {
                    this.isLoading = false;
                    this.undelited = false;
                });
        },

        unDeleteSportsmanFromFriends() {
            if (this.isLoading) return;
            const uuid = this.sportsmanItem.uuid;
            const requestData = {uuid};
            this.isLoading = true;
            apiRequest(SPORTSMANS, requestData, 'post')
                .then(resp => {
                    if (resp && resp.success) {
                        this.isFriendAdded = true;
                    }
                })
                .catch(e => {
                    console.log('error', e)
                })
                .finally(() => {
                    this.isLoading = false;
                    this.undelited = true;
                });
        }


    }
}
</script>

<style>
.loader {
    border: 2px solid #f3f3f3;
    border-top: 2px solid #3498db;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
</style>