<template>
    <dashboardLayout>
        <navigation-container class="mt-10">
            <wrap-section :is-empty="true">
                <div class="flex-1 flex items-center">
                    <h2 class="md:text-4xl text-2xl">Мои тренеры</h2>
                </div>

                <Tabs>
                    <Tab active="true" title="Мои тренеры">
                        <my-trainers-tab/>
                    </Tab>
                    <Tab title="Поиск тренера">
                        <wrap-section>
                            <search-trainer-tab />
                        </wrap-section>
                    </Tab>
                </Tabs>
            </wrap-section>

        </navigation-container>
    </dashboardLayout>
</template>

<script setup>
import {navigateTo, uuid} from "@/utils/helpers";
import {ref} from "vue";
import dashboardLayout from "@/views/layout/dashboard";
import wrapSection from "@/views/components/helpers/wrapSection";
import NavigationContainer from "@/views/layout/components/navigation-container.vue";
import Tabs from "@/views/components/helpers/Tabs.vue";
import Tab from "@/views/components/helpers/Tab.vue";
import useEmitter from "@/utils/useEmitter";
import MyTrainersTab from "@/views/pages/dashboard/trainers/myTrainersTab.vue";
import SearchTrainerTab from "@/views/pages/dashboard/trainers/searchTrainerTab.vue";

let input = ref("");
const emitter = useEmitter();


</script>
