<template>
  <div class="w-full mt-3">
    <div class="wrap_age_gender mt-4">
      <date-picker v-model="fd.date_start" label="Дата начала*" label-class="sm:w-32 font-bold"
                   :future="true" :type="'regStartAt_' + props.index" :disabled="isDisabled"/>
      <div class="mt-4 gender sm:mt-0 sm:flex sm:justify-center">
        <date-picker v-model="fd.date_end" label="Дата окончания*"
                     label-class="sm:w-32 font-bold" :future="true" :type="'regEndAt_' + props.index"
                     :disabled="isDisabled"/>
      </div>
    </div>
  </div>

  <div class="w-full">
    <div class="wrap_input mt-5" v-bind="$attrs">
      <div class="label-personal-page font-bold text-sm flex justify-normal items-center">Выберите цену</div>
      <VueMultiselect
        v-model="fd.price_type"
        :options="store.state.discountEarlyRegPriceType"
        track-by="id"
        label="title"
        deselect-label="Удалить"
        select-label="Выбрать"
        selected-label="Выбрано"
        :multiple="false"
        placeholder="Не выбрана"
        :disabled="isDisabled"
      >
        <template v-slot:noResult class="no-result">
          <span>Тип акции не выбран</span>
        </template>
      </VueMultiselect>

    </div>
  </div>

  <div class="w-full">
    <div class=" mt-4 block sm:flex justify-between">


      <input-percent label="Процент скидки*" v-model="fd.discount_percent" class="mt-4 mob_margin"
                     labelClass="pass-update" input-class="sm:w-44" :disabled="isDisabled"/>

      <input-custom label="Цена со скидкой*" type="text" input-class="sm:w-44"
                    v-model="fd.discount_price" class="mt-4 mob_margin" labelClass="pass-update"
                    disabled
      />
    </div>
  </div>

</template>

<script setup>
import {onMounted, ref, watch} from "vue";
import useEmitter from "@/utils/useEmitter";
import {useRoute} from "vue-router";
import VueMultiselect from "vue-multiselect";
import DatePicker from "@/views/components/inputComponent/datePicker.vue";
import InputCustom from "@/views/components/inputComponent/inputCustom.vue";
import InputPercent from "@/views/components/inputComponent/inputPercent.vue";
import store from "@/store";

const props = defineProps({
  index: {
    type: Number,
    default: 0,
  },
  isDisabled: {
    type: Boolean,
    default: false,
  },
  data: {
    type: Object,
  },

})

const route = useRoute();
const emitter = useEmitter();
const isLoading = ref(false);
const fd = ref({
  price_type: {
    id: '',
    price: '',
    title: ''
  },
  type: 'early_reg',
  date_start: '',
  date_end: '',
  price_id: null,
  discount_percent: null,
  discount_price: null,
  is_all_category: false,
});

let isInitialMount = ref(true);

const initData = () => {
  fd.value.discount_percent = props.data?.discount_percent ?? null;
  fd.value.date_end         = props.data.date_end ?? null;
  fd.value.date_start       = props.data.date_start ?? null;
  fd.value.discount_price   = props.data.discount_price ?? null;
  fd.value.price_type       = getPriceType();
}


// const clearDiscount = () => {
//   if (onMountUpdate.value) return;
//   fd.value.discount = null;
//   fd.value.end_at = null;
//   fd.value.start_at = null;
//   fd.value.discount_price = null;
//   fd.value.price_type = null;
//
// }

onMounted(() => {
  // onMountUpdate.value = true;
  initData();
  isInitialMount.value = false;
});

const getPriceType = () => {
  const targetId = props.data?.price_id ? props.data?.price_id : 'all';
  return store.state.discountEarlyRegPriceType.find(item => item.id === targetId);
}


emitter.on('regStartAt_' + props.index, value => {
  if (!value) return;
  fd.value.date_start = value;
});
emitter.on('regEndAt_' + props.index, value => {
  if (!value) return;
  fd.value.date_end = value;
});

watch(
  fd,
  (newVal, oldVal) => {
    if (isInitialMount.value) return;
    if (fd.value.price_type?.id === 'all') {
      fd.value.is_all_category = true;
      fd.value.discount_price = null;
      fd.value.price_id = null;
    }
    // console.log(typeof fd.value.price_type?.id)
    if(typeof fd.value.price_type?.id === 'number') {
      fd.value.price_id = fd.value.price_type.id;
      fd.value.is_all_category = false;
    }

    if (fd.value.price_type?.price || fd.value.discount) {
      const price = parseFloat(fd.value.price_type.price);
      const discount = parseFloat(fd.value.discount_percent);
      if (!isNaN(price) && !isNaN(discount)) {
        const discountedPrice = Math.round(price * (1 - discount / 100));
        fd.value.discount_price = new Intl.NumberFormat('ru-RU').format(discountedPrice) + " ₽";
      }
    }

    const updatedDiscount = store.state.discount[props.index];
    if (updatedDiscount) {
      store.commit("updateDiscountIndexItem", {
        index: props.index,
        updatedFields: fd.value,
      });
    }
  },
  {deep: true}
);

watch(
  () => props.data,
  (newData, oldData) => {
    if (newData && !isInitialMount) {
      if (
        newData.registration_quantity !== oldData?.registration_quantity ||
        newData.discount_percent !== oldData?.discount_percent
      ) {
        initData();
      }
    }
  },
  { deep: true }
);

</script>