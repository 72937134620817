<template>
    <dashboardLayout>
        <navigation-container class="mt-10">
            <wrap-section :is-empty="true">
                <div class="sm:flex">
                    <div class="flex-1 flex items-center">
                        <h2 class="text-4xl">Мои соревнования</h2>
                    </div>


                    <button class="mt-5 w-full btn btn_blue sm:w-80 sm:mt-0 d_center pr-5 pl-3 "
                            @click="createCompetition" v-if="store.state.permissions.includes('public_create_event')">
                        <icon icon-name="white_plus" class="mr-3"></icon>
                        Создать соревнование
                    </button>
                </div>

                <Tabs>
                    <Tab active="true" title="Предстоящие" :total="upcomingEvents.pagination?.total">
                        <wrap-section :is-empty="true">
                            <competitionCard v-for="el in upcomingEvents?.data" :data="el" class="mt-5" @copyEvent="copyEvent"/>

                            <pagination 
                                :meta="upcomingEvents?.pagination"
                                v-model="upcomingEventsCurrentPage"
                                        class="mt-7"
                            />

                        </wrap-section>

                    </Tab>
                    <Tab title="Архив" :total="pastEvents.pagination?.total">
                        <wrap-section :is-empty="true">
                            <competitionCard v-for="el in pastEvents.data" :data="el" :past-event="true" class="mt-5" />

                            <pagination 
                                :meta="pastEvents?.pagination" 
                                v-model="pastEventsCurrentPage" 
                                class="mt-7"
                            />

                        </wrap-section>
                    </Tab>
                </Tabs>
            </wrap-section>
        </navigation-container>
    </dashboardLayout>
</template>

<script setup>

import dashboardLayout from "@/views/layout/dashboard";
import Tabs from "@/views/components/helpers/Tabs.vue";
import Tab from "@/views/components/helpers/Tab.vue";
import WrapSection from "@/views/components/helpers/wrapSection.vue";
import NavigationContainer from "@/views/layout/components/navigation-container.vue";
import {apiRequest, uuid} from "@/utils/helpers";
import {EVENTS} from "@/router/api";
import {onMounted, ref, watch} from "vue";
import competitionCard from "@/views/components/competitions/competitionsCard.vue";
import {useRoute} from 'vue-router';
import Icon from "@/views/components/helpers/icon.vue";
import router from "@/router/router";
import Pagination from "@/views/components/helpers/pagination.vue";
import store from "@/store";
import { EVENT_COPY } from '@/router/api';
// import {getUpcomingEvents} from "@/utils/helpers";
// import useEmitter from "@/utils/useEmitter";



const route = useRoute();
const eventId = ref(false);
const upcomingEvents = ref({ data: [], pagination: {} });
const upcomingEventsCurrentPage = ref(1);
const pastEvents = ref({ data: [], pagination: {} });
const pastEventsCurrentPage = ref(1);
// const emitter = useEmitter(); 

// emitter.on('upcomingEvents', (result) => {
//     upcomingEvents.value.data = result.data;
//     upcomingEvents.value.pagination = result.pagination;
// });

// const fetchUpcomingEvents = (page) => {
//     getUpcomingEvents(page);
// };

const fetchUpcomingEvents = async (page) => {
    try {
        const resp = await apiRequest(EVENTS + "?event_time=future&self=true&page=" + page, null, 'get'); // загружаются только "мои" сореснования self=true
        
        if (resp && resp.success) {
            upcomingEvents.value.data = resp.result.data;
            upcomingEvents.value.pagination = resp.result.pagination;
        }
    } catch (e) {
        console.error('Ошибка при загрузке предстоящих соревнований:', e);
    }
};



const fetchPastEvents = async (page) => {
    try {
        const resp = await apiRequest(EVENTS + "?event_time=past&self=true&page=" + page, null, 'get'); // загружаются только "мои" сореснования self=true
        if (resp && resp.success) {
            pastEvents.value.data = resp.result.data;
            pastEvents.value.pagination = resp.result.pagination;
        }
    } catch (e) {
        console.error('Ошибка при загрузке прошлых соревнований:', e);
    }
};

onMounted(() => {
    if (route.query.event_id) {
        eventId.value = route.query.event_id;
        return;
    }
    fetchUpcomingEvents(upcomingEventsCurrentPage.value);
    fetchPastEvents(pastEventsCurrentPage.value);

});

watch(upcomingEventsCurrentPage, (newPage) => {
    fetchUpcomingEvents(newPage);
});
watch(pastEventsCurrentPage, (newPage) => {
    fetchPastEvents(newPage);
});

const createCompetition = () => {
    router.push({name: 'create-competition', params: {id: uuid()}})
}

const copyEvent = async (eventData) => {
    console.log('eventData',eventData)
    try {
        const response = await apiRequest(
            `${EVENT_COPY}/${eventData.props_data.uuid}`,
            {
                title: eventData.title,
                start_at: eventData.start_at,
                end_at: eventData.end_at,
                registration_start_at: eventData.registration_start_at,
                registration_end_at: eventData.registration_end_at,
            },
            'post'
        );
        if (response && response.uuid) {
            const newEventData = response;
            console.log('newEventData',newEventData);
            router.push({ 
                name: 'create-competition',
                params: { id: response.uuid },

            });
        } else {
            console.error('Ошибка: не удалось получить новый UUID события');
        }
    } catch (error) {
        console.error('Ошибка при копировании события:', error);
    }
};


</script>
