<template>
    <div v-for="el in myResults" class="mt-4  flex flex-col sm:flex-row gap-3">
        <div class="flex-1">
            <div class="text-dark-base text-xl">
                {{ el.event_name }}
            </div>
            <div class="text-black text-l">
                {{ el.event_direction}}
            </div>
            <div class="text-grey-text text-xs mt-1">
                {{ el.event_place}}
            </div>
            <div class="text-black text-xs mt-1" v-if="el.event_start_at">
                {{ dateToLocal(el.event_start_at)}}
            </div>
        </div>
        <div class="flex-1">
            <div class="grid grid-cols-[2fr,1fr,1fr,0.5fr] gap-4 w-full">
                <div>
                    <div class="text-xs text-dark-base">Направление</div>
                    <div class="text-xs mt-2">{{ el.event_unit_equipment + ", " + el.event_unit_direction + ", " + el.event_unit_level + ", " + el.event_unit_age + " лет"}}</div>
                </div>
                <div>
                    <div class="text-xs text-dark-base d_center">Место</div>
                    <div class="text-xs d_center mt-2">{{ el.position }}</div>
                </div>
                <div>
                    <div class="text-xs text-dark-base d_center">Баллы</div>
                    <div class="text-xs d_center mt-2">{{ el.total_score }}</div>
                </div>
                <div class="flex justify-center justify-self-end">
                    <div class="w-[30px] h-[30px] bg-sky-blue rounded-l justify-center items-center gap-2.5 inline-flex cursor-pointer"
                         @click="openDiploma(el.event_unit_uuid)">
                        <icon icon-name="generateDiploma" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import {dateToLocal} from "@/utils/helpers";
import store from "@/store";
import {computed, ref} from "vue";
import Icon from "@/views/components/helpers/icon.vue";
import router from "@/router/router";
import useEmitter from "@/utils/useEmitter";

const props = defineProps({
    qnt: {
        type: Number,
        default: 0,
    },
});

const emitter = useEmitter();
const resultsLimit = ref(props.qnt || 10);
const myResults = computed(() => {
    return store.getters.getMyResults.slice(0, resultsLimit.value);
});

emitter.on("showAllResults", () => {
    resultsLimit.value = store.getters.getMyResults.length;
});

const openDiploma = (eventUnitUuid) => {
    const url = router.resolve({name: 'diploma', params: {event_unit_uuid: eventUnitUuid}}).href;
    window.open(url + '?sportsman_uuid=' + store.state.user.uuid, '_blank');
}

</script>

<style>
</style>