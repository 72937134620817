<template>
    <dashboardLayout>
        <navigation-container class="mt-10">
            <wrap-section :is-empty=true>
                <div ref="tabsContainer" class="prof_container relative flex flex-col sm:flex-row sm:justify-between">
                    <div class="sm:flex justify-between w-full">
                        <div class="d_center">
                            <h2 class="text-2xl md:text-4xl ">Мои достижения</h2>
                        </div>
                        <button class="mt-5 w-full sm:w-auto sm:mt-0 flex btn d_center pl-8 pr-8 text-sm shadow-base"
                                style="background-color: #F3E8D0; border: unset;"
                                @click="navigateTo('rating')"
                        >
                            <icon icon-name="fullRating" class="mr-3"></icon>
                            Общий рейтинг
                        </button>
                    </div>
                </div>
            </wrap-section>
            <wrap-section>
                <div class="w-full">
                    <my-results-item />
                </div>
            </wrap-section>

            <wrap-section :is-empty=true v-if="showAllBtn">
                <button class="btn btn_white mt-8 py-2.5 px-3 !text-sm d_center w-full" @click="showAll" v-if="store.state.myResults.length > 10">
                    Показать еще
                </button>
            </wrap-section>
        </navigation-container>
    </dashboardLayout>

</template>

<script setup>
import dashboardLayout from "@/views/layout/dashboard";
import WrapSection from "@/views/components/helpers/wrapSection.vue";
import MyResultsItem from "@/views/components/raiting/myResults/myResultsItem.vue";
import NavigationContainer from "@/views/layout/components/navigation-container.vue";
import store from "@/store";
import useEmitter from "@/utils/useEmitter";
import {ref} from "vue";
import Icon from "@/views/components/helpers/icon.vue";
import {navigateTo} from "@/utils/helpers";

const emitter = useEmitter();
const showAllBtn = ref(true);
const showAll = () => {
    emitter.emit('showAllResults');
    showAllBtn.value = false;
}

</script>

<style>
</style>