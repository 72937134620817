<template>
    <div class="flex flex-col mt-10" :class="{'mt-5': store.state.isMinHeight}">
        <div>
            <!-- Основное меню -->
            <div v-for="item in menu" :key="item.url">
                <div class="menu_item flex cursor-pointer sidebar-normal-height relative"
                     v-if="checkPermission(item)"
                     @click="navigateTo(item.url)"
                     :class="{ active: route.name === item.url,
                  '!sidebar-small-height': store.state.isMinHeight,
                  '!sidebar-padding-mob': props.isMobile }"
                >
                    <sidebar-line class="absolute left-0" v-if="props.isMobile"/>

                    <div class="flex items-center mr-3 sidebar_icon">
                        <icon :icon-name="item.icon"/>
                    </div>

                    <div class="menu_name font-bold">{{ item.name }}</div>
                </div>
            </div>


            <sidebar-line class="mb-7" :class="{'mt-7': !props.isMobile}"/>

            <!-- Дополнительное меню -->
            <div class="menu_extra flex cursor-pointer sidebar-normal-height"
                 v-for="item in menu_extra" :key="item.url"
                 @click="navigateTo(item.url, item.openInNewTab)"
                 :class="{'!sidebar-small-height': store.state.isMinHeight,
                '!sidebar-padding-mob': props.isMobile }"
            >
                <div class="flex items-center mr-3 sidebar_icon">
                    <icon :icon-name="item.icon"/>
                </div>
                <div class="menu_name">{{ item.name }}</div>
            </div>
        </div>
    </div>
</template>

<script setup>
import Icon from "@/views/components/helpers/icon.vue";
import sidebarLine from "@/views/layout/components/sidebar-line.vue";
import {navigateTo} from "@/utils/helpers";
import {useRoute} from "vue-router";
import store from "@/store";

let route = useRoute();

const props = defineProps({
    isMobile: Boolean,
    default: false,
});

const checkPermission = (item) => {
    return !(item.permission && !store.state?.permissions?.includes(item.permission));
}

const menu = [
    {url: 'dashboard', icon: 'profile', name: 'Моя страница', permission: false},
    {url: 'sportsmans', icon: 'sportsman', name: 'Мои спортсмены', permission: 'public_view_my_sportsmen'},
    // заготовка для личного кабинета родителей/представителей
    // {url: 'my-represented-sportsman', icon: 'sportsman', name: 'Мои подопечные', permission: 'public_view_my_sportsmen'},
    {url: 'trainers', icon: 'myTrainers', name: 'Мои тренеры', permission: "public_view_my_trainer"},
    {url: 'calendar', icon: 'calendar', name: 'Календарь соревнований', permission: "public_view_public_events"},
    {url: 'my-competitions', icon: 'competitions', name: 'Мои соревнования', permission: "public_trainer_my_events"},
    {url: 'my-registrations', icon: 'competitions', name: 'Мои регистрации', permission: 'public_sportsman_my_registration'},
    {url: 'payment', icon: 'payment', name: 'Оплата соревнований', permission: "public_trainer_pay_events"},
    // {url: 'create-competition', icon: 'organisations', name: 'Организации', permission: false},
    {url: 'rating', icon: 'rating', name: 'Рейтинги', permission: false},
];
const menu_extra = [
    {url: 'https://help.wafed.org', icon: 'information', name: 'Инструкции', openInNewTab: true},
    {url: 'https://form.asana.com/?k=SOvvuQIJ0pIyYjo5SmduTw&d=1140471344364532', icon: 'support', name: 'Поддержка', openInNewTab: true},
];

</script>


<style scoped>
.menu_name {
    font-size: 14px;
}

.sidebar_icon {
    width: 25px;
}

.wrap_menu {
    margin-top: 40px;
}

.menu_item div, .menu_extra div {
    color: var(--white);
}

.menu_item {
    padding: 0 15px 0 50px;
}

.menu_extra {
    padding: 0 15px 0 50px;
}

.menu_item:hover, .active {
    background-color: var(--dark-base);
}
</style>