<template>
    <dashboardLayout>
        <navigation-container :navigation-data="navData"
                              :btn="{title: 'Назад', route: 'my-competitions'}">
            <div class="flex justify-center mb-10">
                <div class="w-full lg:w-auto">
                    <div class="prof_container relative flex">
                        <wrap-section :is-empty=true >
                            <div class="flex justify-between">
                                <h2 class="text-2xl md:text-4xl ">Создание соревнования</h2>
                                <toggle-switch :data="data" />
                            </div>

                            <p class="text-base mt-5">Заполните обязательную информацию о соревновании</p>
                        </wrap-section>
                    </div>
                    <div class="prof_container relative flex" id="id">
                        <wrap-section>
                            <span class="text-dark-base text-xl font-bold">Обложка соревнования</span><br><br>
                            <div class="flex flex-wrap sm:flex-nowrap">
                                <div v-if="fd.competitionBanner" class="w-full relative">
                                    <div class="absolute right-0 bg-tooltip text-white flex items-center p-4 rounded-2xl cursor-pointer" @click="deleteBanner">
                                        Поменять обложку <icon icon-name="delete_white" class="ml-3"></icon>
                                    </div>
                                    <img :src="fd.competitionBanner" alt="" class="w-full rounded-2xl">
                                </div>
                                <div class="w-full mt-4 sm:mt-0" v-else>
                                    <span class="font-bold">Загрузите фотографию</span> <br>
                                    <span class="text-base">В форматах JPG, PNG, размера 680х300 px</span>
                                    <div class="mt-3">
                                        <file-upload uploadType="competitionBanner"
                                                     message="Ошибка при загрузке баннера для соревнования"
                                                     :return-url="true"
                                                     :event-id="event_uuid"
                                        />
                                    </div>
                                </div>
                            </div>
                        </wrap-section>
                    </div>
                    <div class="prof_container mt-8" id="info">
                        <wrap-section>
                            <span class="text-dark-base text-xl font-bold">Информация о соревновании</span><br><br>
                            <competition-info :data="data" />
                        </wrap-section>
                    </div>

                    <div class="prof_container mt-8" id="condition">
                        <wrap-section>
                            <span class="text-dark-base text-xl font-bold">Условия участия в соревновании</span><br><br>
                            <competition-condition :data="data" />
                        </wrap-section>
                    </div>

                    <div class="prof_container mt-8" id="condition">
                        <wrap-section>
                            <span class="text-dark-base text-xl font-bold">Акции</span><br><br>
                            <competition-offer />
                        </wrap-section>
                    </div>

                    <div class="prof_container mt-8" id="search">
                        <wrap-section>
                            <span class="text-dark-base text-xl font-bold">Добавить представителей</span><br><br>
                            <Tabs class="text-sm sm:text-base">
                                <Tab active="true" title="Представители" :total="represent_total">
                                    <represent-added v-model="represent_total" />
                                </Tab>
                                <Tab title="Поиск">
                                    <represent-search class="mt-8"/>
                                </Tab>
                            </Tabs>

                        </wrap-section>
                    </div>

                    <div class="prof_container mt-8" id="docs">
                        <wrap-section>
                            <span class="text-dark-base text-xl font-bold">Добавить документы</span><br><br>
                            <competition-docs />
                        </wrap-section>
                    </div>

                    <div class="prof_container mt-8" id="faq">
                        <wrap-section>
                            <span class="text-dark-base text-xl font-bold">Вопросы и ответы</span><br><br>
                            <competition-faq :data="data" />
                        </wrap-section>
                    </div>
                    <div class="prof_container mt-8" id="faq">
                        <wrap-section :is-empty="true" >
                            <alerts alertType="publishEvent"/>
                            <div class="flex justify-end">
                                <button class="btn mt-8 btn_blue w-full sm:w-64" @click="publishEvent">Опубликовать</button>
                            </div>
                        </wrap-section>
                    </div>
                </div>
            </div>
        </navigation-container>
    </dashboardLayout>
</template>

<script setup>

import dashboardLayout from "@/views/layout/dashboard";
import WrapSection from "@/views/components/helpers/wrapSection.vue";
import FileUpload from "@/views/components/dashboard/fileUpload.vue";
import CompetitionInfo from "@/views/components/createCompetition/competitionInfo.vue";
import NavigationContainer from "@/views/layout/components/navigation-container.vue";
import useEmitter from "@/utils/useEmitter";
import {onMounted, ref} from "vue";
import Icon from "@/views/components/helpers/icon.vue";
import CompetitionCondition from "@/views/components/createCompetition/competitionCondition.vue";
import CompetitionDocs from "@/views/components/createCompetition/competitionDocs.vue";
import CompetitionFaq from "@/views/components/createCompetition/competitionFaq.vue";
import Tab from "@/views/components/helpers/Tab.vue";
import Tabs from "@/views/components/helpers/Tabs.vue";
import RepresentSearch from "@/views/components/createCompetition/representSearch.vue";
import RepresentAdded from "@/views/components/createCompetition/representAdded.vue";
import {useRoute} from "vue-router";
import {apiRequest, personalDataUpdate} from "@/utils/helpers";
import {EVENTS, FILES} from "@/router/api";
import ToggleSwitch from "@/views/components/inputComponent/toggleSwitch.vue";
import Alerts from "@/views/components/helpers/alerts.vue";
import CompetitionOffer from "@/views/components/createCompetition/discount/competitionOffer.vue";

const emitter = useEmitter();
const represent_total = ref(0);
const route = useRoute();
const event_uuid = ref(route.params.id);
const data = ref({});

const navData = [
    {name: 'Обложка', id: 'id'},
    {name: 'Информация', id: 'info'},
    {name: 'Условия и стоимость', id: 'condition'},
    {name: 'Представители', id: 'search'},
    {name: 'Документы', id: 'docs'},
    {name: 'Вопросы и ответы', id: 'faq'},
];

const fd = ref({
    competitionBanner: '',
    avatar: '',
    represent: '',
});

const deleteBanner = async () => {
    await apiRequest(EVENTS + '/' + route.params.id, {type: 'banner'}, 'delete')
        .catch(e => {console.log('Error', e);})
        .then(async resp => {
            if (resp && resp.success) {
                fd.value.competitionBanner = '';
            }
        })

}

const publishEvent = () => {
    apiRequest(EVENTS + '/' + route.params.id, {type: 'toggleActive', is_active: true}, 'put')
        .catch(e => {
            console.log('error', e)
        })
        .then(resp => {
            if(resp.success) {
                emitter.emit("success", {alertType: 'publishEvent',
                    message: 'Соревнование успешно опубликовано'});
                data.value.is_active = 1;
            }
        })
}

const getCompetitionData = async () => {
    await apiRequest(EVENTS + '/' + route.params.id, {}, 'get')
        .catch(e => {
            // if(!props?.message) return ;
            // emitter.emit("alert", {alertType: props.uploadType, message: props.message});
        })
        .then(async resp => {
            if (resp && resp.success) {
                fd.value.competitionBanner = resp.result.banner_url ? resp.result.banner_url: '';
                data.value = resp.result;
            }
        })
}

onMounted(() => {
    getCompetitionData();
});

emitter.on('returnUrl', value => {
    if (value.uploadType === 'competitionBanner') fd.value.competitionBanner = value.url;
})
</script>


