import {createStore} from 'vuex'
import {logout} from "@/utils/helpers";

const store = createStore({
    state() {
        return {
            isAuthenticated: false,
            userStatus: 'draft',
            isMinHeight: false,
            isMobile: false,
            personalDoc: [],
            permissions: [],
            user: {},
            roles: [],
            avatar: false,
            organisation: [],
            discount: [],
            discountType: [],
            discountEarlyRegPriceType: [],
            direction: [],
            sportsman: [],
            myTrainers: [],
            myResults: [],
            registrationOptions: {
                category: [],
                equipments: [],
                levels: [],
            },
            directionCategories: [],
            sportsmans: {},
            upcomingEvents: {},
        }
    },
    mutations: {
        login(state, data) {
            state.isAuthenticated = true;
            state.user   = data;
            state.avatar = data.avatar;
            state.roles  = data.roles;
            state.userStatus  = data.status;
            state.personalDoc = data.personal_doc;
            state.permissions = data.permissions;
            state.myTrainers  = data.trainer;
            state.myResults   = data.results;
        },
        clearDiscount(state) {
            state.discount = [];
        },
        updateDiscount(state, discountData ) {
            state.discount.push(discountData);
        },
        updateDiscountPriceType(state, data ) {
            state.discountEarlyRegPriceType = data;
        },
        updateDiscountType(state, discountTypeData ) {
            state.discountType = discountTypeData;
        },
        updateDiscountIndexItem(state, { index, updatedFields }) {
            const discount = state.discount[index];
            if (discount) {
                Object.keys(updatedFields).forEach((key) => {
                    if (key in discount) {
                        discount[key] = updatedFields[key];
                    }
                });
            }
        },

        removeDiscountByIndex(state, index) {
            if (index >= 0 && index < state.discount.length) {
                state.discount.splice(index, 1);
            }
        },
        authStatus(state, data) {
            state.isAuthenticated = true;
            state.user = data;
        },
        upcomingEvents(state, data) {state.upcomingEvents = data.result.data},
        rolesUpdate(state, data) {state.roles = data.result;},
        personalDocDelete(state) {state.personalDoc = []},
        logout(state) {state.isAuthenticated = false;},
        setAvatar(state, data) {state.avatar = data.avatar;},
        setOrganisation(state, data) {state.organisation = data;},
        setDirection(state, data) {state.direction = data;},
        setRegistrationOptions(state, data) {state.registrationOptions = data;},
        setTrainers(state, data) {state.myTrainers = data;},

        setDirectionCategories(state, data) {state.directionCategories = data;},

        setSportsman(state, data) {state.sportsman = data;},
        setSportsmans(state, data) {state.sportsmans = data;},
        setSportsmansData(state, data) {state.sportsmansData = data;},
        updateIsMobile(state, data) {state.isMobile = data;},
        updateMinHeight(state, data) {state.isMinHeight = data;}
    },
    getters: {
        isAuthenticated(state) {
            return state.isAuthenticated;
        },
        isValidate(state) {
            return state.isValidate;
        },
        isMobile(state) {
            return state.isMobile;
        },

        getUserEmail: state => state.user.email ?? 'undefined',
        getRegistrationOptions: state => state.registrationOptions,

        getDirectionCategories (state) {
            return state.directionCategories
        },

        getUserFio(state) {
            return state.user.name + ' ' + state.user.surname;
        },
        getUserRoles(state) {
            // обработка массива для исключения super роли
            return state.roles.filter(item => item !== 'super');
            },
        getUserGender(state) {
            if (state.user?.sex === 'male') {
                return 'male';
            }
            return 'female';
        },
        getUserOrganisations(state) {
            return state.user.organisations
        },
        getUserBirthday(state) {
            return state.user.birthday;
        },
        getOrganisations(state) {
            return state.organisation;
        },
        getUserDirections(state) {
            return state.user.direction
        },
        getDirections(state) {
            return state.direction;
        },
        isMinHeight(state) {return state.isMinHeight;},
        getUserAvatar(state) {
            if (!state.avatar) {
                if(state.user.sex === 'male') return {isSet: false, url: '/img/avatar/male.svg'};
                return {isSet: false, url: '/img/avatar/female.svg'};
            }
            return {isSet: true, url: state.avatar};
        },
        getUserInfo(state) {
           return state.user;
        },
        getMyTrainers(state) {
            return state.myTrainers;
        },
        getMyResults(state) {
            if (!state.myResults || state.myResults.length === 0) {
                return [];
            }

            return [...state.myResults].sort((a, b) => {
                const dateA = new Date(a.event_start_at);
                const dateB = new Date(b.event_start_at);
                return dateB - dateA;
            });
        }
    }
})

export default store;