<template>
    <dashboardLayout>
        <navigation-container :navigation-data="navData"
                              :btn="{title: 'Вернуться на личную страницу', route: 'dashboard'}">
            <modal>
                <div class="text-left text-base-color font-bold text-lg">Привет</div>
                <div class="text-sm mt-5">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquid aspernatur blanditiis commodi, facilis impedit itaque obcaecati porro provident quia quis, sit tempore tenetur. Dolorum eveniet laborum maiores mollitia repellendus veritatis!</div>
                <button class="btn btn_blue w-full mt-5">Hello</button>
            </modal>
            <modal :visible="showModal" @closeModal="closeModal">
                <div v-if="img">
                    <h2 class="text-lg font-bold">Редактировать фото</h2>
                    <imageCropper :imageToCrop="img" @imageCropped="onImageCropped" />
                    <div class="relative w-full mt-5">
                        <button :disabled="isLoading" 
                            :class="{
                                'btn_white !text-white': isLoading,
                                'btn_blue': !isLoading
                            }"
                            class="btn w-full mt-5" @click="saveCroppedImage">
                            Сохранить
                        </button>
                        <preloader class="absolute inset-0 flex items-center justify-center z-10" v-if="isLoading" />
                    </div>
                </div>
            </modal>
            <div class="flex justify-center mb-10">
                <div class="w-full lg:w-auto">
                    <div class="prof_container relative flex">
                        <wrap-section :is-empty=true >
                            <h2 class="text-2xl md:text-4xl ">Редактирование профиля</h2>
                            <p class="text-base mt-5">Заполните обязательную информацию о себе для участия в
                                соревнованиях</p>
                        </wrap-section>
                    </div>
                    <red-alert v-if="alertRef" :info="bannerMessage" alertType="error" />
                    <div class="prof_container relative flex" id="photo">
                        <wrap-section>
                            <span class="text-dark-base text-xl font-bold">Фото</span><br><br>
                            <div class="flex flex-wrap sm:flex-nowrap">
                                <div v-if="store.getters.getUserAvatar.isSet"
                                     class="sm:mr-5 flex items-center justify-center w-28 h-28 sm:w-32 mx-auto">
                                     <div @click="openModal" class="relative group cursor-pointer transition-opacity duration-200 hover:opacity-50">
                                        <img :src="store.getters.getUserAvatar.url" alt="" class="rounded-full">
                                        <icon class="absolute top-9 right-9 w-6 h-6 opacity-0 transition-opacity duration-200 group-hover:opacity-100" icon-name="pen" />
                                        <div class="text-base-color text-sm" @click="openModal">
                                            Редактировать
                                        </div>
                                     </div>
                                </div>
                                <div class="w-full mt-4 sm:mt-0">
                                    <span class="font-bold">Загрузите фотографию</span> <br>
                                    <span class="text-base">В форматах JPG, PNG</span>
                                    <div class="mt-3">
                                        <file-upload uploadType="avatar" message="Ошибка при загрузке Аватара"/>
                                    </div>
                                </div>
                            </div>
                        </wrap-section>
                    </div>
                    <div class="prof_container mt-8" id="personal">
                        <wrap-section>
                            <div class="flex justify-between">
                                <span class="text-dark-base text-xl font-bold">Персональные данные</span>
                                <div class="d_center">
                                    <validate-modal />
                                </div>
                            </div>
                            <br><br>
                            <personal-data/>
                        </wrap-section>
                    </div>
                    <div class="prof_container mt-8" id="role">
                        <add-roles/>
                    </div>
                    <div class="prof_container mt-8" id="password">
                        <change-password/>
                    </div>

                </div>
            </div>
        </navigation-container>
    </dashboardLayout>
</template>

<script setup>
import dashboardLayout from "@/views/layout/dashboard";
import WrapSection from "@/views/components/helpers/wrapSection.vue";
import FileUpload from "@/views/components/dashboard/fileUpload.vue";
import store from "@/store";
import AddRoles from "@/views/components/dashboard/addRoles.vue";
import ChangePassword from "@/views/components/dashboard/changePassword.vue";
import PersonalData from "@/views/components/dashboard/personalData.vue";
import NavigationContainer from "@/views/layout/components/navigation-container.vue";
import Modal from "@/views/components/modal/modal.vue";
import useEmitter from "@/utils/useEmitter";
import {apiRequest, personalDataUpdate} from "@/utils/helpers";
import {FILES, GET_IMAGE} from "@/router/api";
import imageCropper from "@/views/components/helpers/imageCropper.vue";
import preloader from "@/views/components/helpers/preloader.vue";
import Icon from "@/views/components/helpers/icon.vue";


import ValidateModal from "@/views/components/modal/validateModal.vue";
import "vue-advanced-cropper/dist/style.css";
import { ref } from "vue";
import {useRoute} from "vue-router";
import axios from "axios";

const isLoading = ref(false);

const route = useRoute();

const navData = [
    {name: 'Фото', id: 'photo'},
    {name: 'Персональные данные', id: 'personal'},
    {name: 'Роль на платформе', id: 'role'},
    {name: 'Пароль', id: 'password'},
];

const croppedImage = ref("");
const showModal = ref(false);
const img = ref("");

const emitter = useEmitter();


const openModal = async () => {
    showModal.value = true;
    await loadImage();
};

const closeModal = () => {
    showModal.value = false;
};

function onImageCropped(croppedData) {
    croppedImage.value = croppedData;  // Получаем обрезанное изображение
}

async function saveCroppedImage() {
    if (!croppedImage.value) {
        console.error("Нет обрезанного изображения!");
        return;
    }

    isLoading.value = true;

    try {
        // преобр base64 в Blob
        const imageBlob = await dataURLtoBlob(croppedImage.value);

        // создание объекта FormData для отправки изображения
        const formData = new FormData();
        formData.append('type', 'avatar');
        formData.append('userId', route?.params?.id ?? '');
        formData.append('image[]', imageBlob, 'avatar.webp');

        await apiRequest(FILES, formData)
        .catch(e => {
            emitter.emit("alert", {alertType: 'avatar', message: 'ошибка при загрузке аватара'});
        })  
        .then(async resp => {
            if (resp && resp.success) {
                await personalDataUpdate();
            }
        })
        
    } catch (error) {
        console.error("Ошибка при сохранении изображения:", error);
        emitter.emit("alert", { alertType: 'avatar', message: 'Не удалось сохранить фото' });
    } finally {
        isLoading.value = false;
        closeModal();
    }
}

// конвертация base64 в Blob
async function dataURLtoBlob(dataURL) {
    const res = await fetch(dataURL);
    return await res.blob();
}

emitter.on('closeModal', () => {closeModal();});

emitter.on('updateAvatar', (data) => {
    avatarUrl.value = data.newAvatarUrl;
});

// загрузка изоражения

const fetchImage = async (user_id) => {
    try {
        // Указываем `responseType: 'blob'` для загрузки изображения как бинарных данных
        const response = await axios.get(`${GET_IMAGE}/${user_id}`, {
            responseType: 'blob',
        });

        return response.data; // `response.data` будет содержать Blob
    } catch (error) {
        console.error(`Ошибка при загрузке изображения для пользователя ${user_id}:`, error);
        throw error; // Прокидываем ошибку выше
    }
};

async function loadImage() {
    const user_id = store.getters.getUserInfo.id; // Получаем user_id из параметров маршрута (или из store, если нужно)

    try {
        const blob = await fetchImage(user_id); // Передаем user_id для получения аватара
        const url = URL.createObjectURL(blob); // Создаем URL объекта
        img.value = url; // Обновляем реактивную переменную img

        const imgElement = new Image();
        imgElement.src = url;

        imgElement.onload = function () {
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");
            canvas.width = imgElement.width;
            canvas.height = imgElement.height;
            ctx.drawImage(imgElement, 0, 0);
        };
    } catch (error) {
        console.error("Error loading image:", error);
    }
}


</script>


