<template>
    <div class="flex sportsman_item pb-7 pt-7">
        <base-user-info :data="sportsmanItem" />

        <div class="flex-auto text-right relative">
                <span v-if="sportsmanItem.isTrainerForUser || isFriendAdded">
                    <span class="text-xs text-green-message-text bg-green-message p-2.5 rounded-2xl hidden md:inline">Добавлено</span>
                    <icon icon-name="green_circle_plus" class="inline-block md:hidden"/>
                </span>
                <button @click="addTrainerToFriend" v-else :disabled="isLoading"
                        :class="{ 'hidden': isLoading, 'md:inline': !isLoading }">
                    <icon icon-name="plus" class="hidden -mb-0.5 md:inline-block mr-2"/>
                    <span class="text-sm text-base-color hidden md:inline">Добавить</span>
                    <icon icon-name="blue_circle_plus" class="inline-block md:hidden"/>
                </button>
                <div v-if="isLoading" class="loader inline-block ml-1"></div>
        </div>
    </div>
</template>

<script>
import avatarMale from "@/../public/img/avatar/male.svg";
import avatarFemale from "@/../public/img/avatar/female.svg";
import icon from "@/views/components/helpers/icon.vue";
import store from "../../../../store";
import {apiRequest} from "@/utils/helpers";
import {ALL_TRAINERS_SEARCH, SPORTSMANS} from "@/router/api";
import BaseUserInfo from "@/views/components/profile/baseUserInfo.vue";


export default {
    data: () => {
        return {
            avatarMale: avatarMale,
            avatarFemale: avatarFemale,
            isFriendAdded: false,
            isLoading: false,
        }
    },
    props: {
        sportsmanItem: {
            type: Object,
            required: true,
        }
    },
    components: {
        BaseUserInfo,
        icon
    },
    computed: {
        sex() {
            return this.sportsmanItem.sex === 'male' ? 'Муж' : 'Жен';
        },
        avatar() {
            return this.sportsmanItem.sex === 'male' ? avatarMale : avatarFemale;
        },
        permissions() {
            return store.state.permissions;
        }
    },
    methods: {
        addTrainerToFriend() {
            if (this.isLoading) return;
            const uuid = this.sportsmanItem.uuid;
            const requestData = {uuid};
            this.isLoading = true;
            apiRequest(ALL_TRAINERS_SEARCH, requestData, 'post')
                .then(resp => {
                    if (resp && resp.success && resp.result) {
                        store.commit('setTrainers', resp.result);
                        this.isFriendAdded = true;
                    }
                })
                .catch(e => {
                    console.log('error', e)
                })
                .finally(() => {
                    this.isLoading = false;
                });
        }
    },
}
</script>

<style>
.loader {
    border: 2px solid #f3f3f3;
    border-top: 2px solid #3498db;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
</style>