<template>
  <div
    class="flex sidebar-padding mt-7 mb-7 relative cursor-pointer"
    @click="handleNavigation('profile')"
    :class="addClass"
  >
    <icon
      icon-name="setup"
      class="absolute right-4 top-1.5"
      :class="{ '!right-10': isMobile }"
    />
    <div class="d_center mr-2">
      <img :src="getUserAvatar.url" alt="" class="w-10 h-10 rounded-full" />
    </div>
    <div class="d_center">
      <div class="truncate max-w-44">
        <div class="text-main text-base-color">
          {{ getUserFio }}
        </div>
        <div class="text-sm text-white">
          {{ getUserEmail }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import { navigateTo } from "@/utils/helpers";
import Icon from "@/views/components/helpers/icon.vue";

defineProps({
  addClass: {
    type: String,
    default: null,
  },
});

const store = useStore();
const getUserAvatar = computed(() => store.getters.getUserAvatar);
const getUserEmail = computed(() => store.getters.getUserEmail);
const getUserFio = computed(() => store.getters.getUserFio);
const isMobile = computed(() => store.getters.isMobile);

const handleNavigation = (urlName) => {
  navigateTo(urlName);
};
</script>


<style scoped>

</style>