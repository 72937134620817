<template>
    <baseLayout>
        <div class="flex justify-center mb-10 ml-2 mr-2">
            <div class="w-full lg:w-auto">
                <wrap-section :is-empty="true">
                    <div class="d_center mt-20">
                        <img v-if="diplomaUrl" :src="diplomaUrl" alt="Diploma" />
                    </div>
                </wrap-section>
            </div>
        </div>
    </baseLayout>
</template>

<script setup>
import baseLayout from "@/views/layout/base";
import WrapSection from "@/views/components/helpers/wrapSection.vue";
import { apiRequest } from "@/utils/helpers";
import { GENERATE_DIPLOMA } from "@/router/api";
import { useRoute } from "vue-router";
import { ref, onMounted } from "vue";

const route = useRoute();
const diplomaUrl = ref();

const generateDiploma = async () => {
    try {
        const resp = await apiRequest(GENERATE_DIPLOMA + '?uuid=' + route.params.event_unit_uuid
            + '&user_uuid=' + route.query.sportsman_uuid, [], 'GET');
        console.log(resp && resp.success)
        if (resp) {
            const isProduction = process.env.NODE_ENV === 'production';
            diplomaUrl.value = isProduction ? resp.result : 'http://127.0.0.1:8000' + resp.result;
        }
    } catch (e) {
        console.error(e);
    }
};

onMounted(() => {
    generateDiploma();
});
</script>
