<template>
    <div @mouseover="showTooltip" @mouseleave="hideTooltip" @touchstart="showTooltip" @touchend="hideTooltip" class="relative inline-block cursor-help">
        <div v-if="isTooltipVisible" class="relative text-white p-3 whitespace-nowrap tooltip text-xs bg-tooltip font-normal">
            {{ tooltipText }}
            <icon icon-name="tooltip" class="absolute tooltip-arrow"/>
        </div>
        <slot></slot>
    </div>
</template>

<script>
import { ref } from 'vue';
import Icon from "@/views/components/helpers/icon.vue";

export default {
    name: 'Tooltip',
    components: {Icon},
    props: {
        content: {
            type: String,
            required: true
        }
    },
    setup(props) {
        const isTooltipVisible = ref(false);
        const showTooltip = () => {
            isTooltipVisible.value = true;
        };
        const hideTooltip = () => {
            isTooltipVisible.value = false;
        };

        return {
            isTooltipVisible,
            showTooltip,
            hideTooltip,
            tooltipText: props.content
        };
    }
};
</script>

<style>
.tooltip-arrow {
    left: 50%;
    transform: rotateX(180deg) translateX(-50%);
}
.tooltip {
    position: absolute;
    top: -50px;
    left: 50%;
    transform: translateX(-50%);
    color: #fff;
    border-radius: 15px;
}
</style>
