<template>
    <div>
        <cropper ref="cropperRef" :src="imageToCrop" @change="onCropChange" />
        <div class="button-wrapper mt-3 flex justify-center gap-3">
            <button class="btn" @click="rotateImage(-90)">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path d="M12 3a8.959 8.959 0 0 0-7 3.339V4H3v6h6V8H6.274a6.982 6.982 0 1 1-1.054 5.751l-1.936.5A9 9 0 1 0 12 3z"/></svg>
            </button>
            <button class="btn" @click="rotateImage(90)">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path d="M15 10h6V4h-2v2.339a9 9 0 1 0 1.716 7.91l-1.936-.5A7.028 7.028 0 1 1 17.726 8H15z"/></svg>
            </button>
        </div>
    </div>
</template>

<script setup>
import { Cropper } from "vue-advanced-cropper";
import { ref, defineProps, defineEmits } from "vue";

const emit = defineEmits();

const cropperRef = ref(null);

const props = defineProps({
  imageToCrop: {
    type: String,
    required: true,
  },
});


function rotateImage(direction) {
  if (cropperRef.value) {
    cropperRef.value.rotate(direction);
  } else {
    console.error("Cropper instance is not available!");
  }
}

function onCropChange() {
    const canvas = cropperRef.value.getResult().canvas;
    if (canvas) {
        const croppedImage = canvas.toDataURL();
        emit("imageCropped", croppedImage);
    }
}

</script>