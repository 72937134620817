<template>

    <div class="w-full">
        <div class=" mt-4 block sm:flex justify-between">

            <input-custom label="Количество участников*" type="number" v-model="fd.registration_quantity" class="mt-4 mob_margin"
                          labelClass="pass-update-offer" input-class="sm:w-44"
                          :min="1" :max="90" :disabled="isDisabled"/>

            <input-percent label="Процент скидки*" v-model="fd.discount_percent" class="mt-4 mob_margin"
                           labelClass="pass-update" input-class="sm:w-44" :disabled="isDisabled"/>

        </div>
    </div>

</template>

<script setup>
import {onMounted, ref, watch} from "vue";
import useEmitter from "@/utils/useEmitter";
import {useRoute} from "vue-router";
import InputCustom from "@/views/components/inputComponent/inputCustom.vue";
import InputPercent from "@/views/components/inputComponent/inputPercent.vue";
import store from "@/store";

const props = defineProps({
    index: {
        type: Number,
        default: 0,
    },
    isDisabled: {
        type: Boolean,
        default: false,
    },
    data: {
      type: Object,
    },
})

const route = useRoute();
const emitter = useEmitter();
const isLoading = ref(false);
let isInitialMount = true;
const fd = ref({
    type: "qnt_reg",
    registration_quantity: null,
    discount_percent: null
});
const initData = () => {
  fd.value.registration_quantity  = props.data?.registration_quantity;
  fd.value.discount_percent       = props.data?.discount_percent;
}

onMounted(() => {
  initData();
  isInitialMount = false;
});

watch(
    fd,
    (newVal, oldVal) => {
        if (isInitialMount) return;

        console.log("Изменения в fd:", { newVal, oldVal });

        const updatedDiscount = store.state.discount[props.index];
        if (updatedDiscount) {
            Object.keys(fd.value).forEach((key) => {
                if (key in updatedDiscount) {
                    updatedDiscount[key] = newVal[key];
                }
            });
            store.commit("updateDiscountIndexItem", {
                index: props.index,
                updatedFields: fd.value,
            });
        }
    },
    { deep: true }
);

watch(
  () => props.data,
  (newData, oldData) => {
    if (newData && !isInitialMount) {
      if (
        newData.registration_quantity !== oldData?.registration_quantity ||
        newData.discount_percent !== oldData?.discount_percent
      ) {
        initData();
      }
    }
  },
  { deep: true }
);
</script>